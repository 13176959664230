import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'components /form_style.css'; // Removed space between 'components' and '/form_style.css'

// Lazy-loaded components
const WaForm = lazy(() => import('components /wa_form')); // Removed space
const MainForm = lazy(() => import('components /main_form')); // Removed space
const ManagerForm = lazy(() => import('components /manager_form')); // Removed space
const QuizForm = lazy(() => import('components /quiz_form')); // Removed space
// const ManagerPage = lazy(() => import('components /manager/ManagerPage')); // Additional component for /manager/* route
const ManagerPage = React.lazy(() => import('components /Manager'));

const App = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0]; // Get the subdomain

  let routes;

  switch (subdomain) {
    case 'wa':
      routes = (
        <Routes>
          <Route path="/" element={<WaForm />} />
          {/* Additional routes for 'wa' subdomain if needed */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
      break;

    case 'manager':
      routes = (
        <Routes>
          <Route path="/" element={<ManagerForm />} />
          <Route path="/manager/*" element={<ManagerPage />} />
          <Route path="/form/*" element={<MainForm />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
      break;

    case 'quiz':
      routes = (
        <Routes>
          <Route path="/" element={<QuizForm />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
      break;

    default:
      routes = (
        <Routes>
          <Route path="/" element={<MainForm />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
      break;
  }

  return (
    <Router
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <Suspense fallback={<h1>Загрузка...</h1>}>
        {routes}
      </Suspense>
    </Router>
  );
};

export default App;
